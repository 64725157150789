import React, { useState } from 'react';
import { fetchToken } from './tokenUtils';

const SApnaSochEndPoint = process.env.REACT_APP_API_ENDPOINT;

const RemoveAccount = () => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [userId, setUserId] = useState('');
    const [message, setMessage] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);

    const sendOTP = async () => {
        try {
            const token = await fetchToken(); // Fetch the token
            if (!token) {
                setMessage('Failed to retrieve access token.');
                return;
            }

            const response = await fetch(`${SApnaSochEndPoint}/SendVerifyOTP`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include Bearer token
                },
                body: JSON.stringify({ to: mobileNumber }),
            });

            if (response.ok) {
                setOtpSent(true);
                setMessage('OTP sent successfully.');
            } else {
                setMessage('Failed to send OTP. Please try again.');
            }
        } catch (error) {
            setMessage('An error occurred while sending OTP.');
        }
    };

    const verifyOTP = async () => {
        try {
            const token = await fetchToken(); // Fetch the token
            if (!token) {
                setMessage('Failed to retrieve access token.');
                return;
            }

            const response = await fetch(`${SApnaSochEndPoint}/ValidateOTP`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include Bearer token
                },
                body: JSON.stringify({ to: mobileNumber, otpCode: otp }),
            });

            if (response.ok && response?.status === 'approved') {
                setOtpVerified(true);
                setMessage('OTP verified successfully.');
                await fetchUserDetails();
            } else {
                setMessage('Invalid OTP. Please try again.');
            }
        } catch (error) {
            setMessage('An error occurred while verifying OTP.');
        }
    };

    const fetchUserDetails = async () => {
        try {
            const token = await fetchToken(); // Fetch the token
            if (!token) {
                setMessage('Failed to retrieve access token.');
                return;
            }

            const response = await fetch(`${SApnaSochEndPoint}/GetUserByMobileNumberAsync?mobileNumber=${encodeURIComponent(mobileNumber)}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Include Bearer token
                },
            });

            if (response.ok) {
                const userData = await response.json();
                setUserId(userData?.id); // Assuming userData contains userId
            } else {
                setMessage('No user found with this mobile number.');
            }
        } catch (error) {
            setMessage('An error occurred while fetching user details.');
        }
    };

    const deleteUserAccount = async () => {
        if (!userId) {
            setMessage('User details not available for deletion.');
            return;
        }

        try {
            const token = await fetchToken(); // Fetch the token
            if (!token) {
                setMessage('Failed to retrieve access token.');
                return;
            }

            const response = await fetch(`${SApnaSochEndPoint}/DeleteUserByIDAsync?userId=${userId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`, // Include Bearer token
                },
            });

            if (response.ok) {
                setMessage('User account deleted successfully.');
            } else {
                setMessage('Failed to delete user account.');
            }
        } catch (error) {
            setMessage('An error occurred while deleting the account.');
        }
    };

    return (
        <div>
            <h2>Remove Account</h2>
            <div>
                <label>Mobile Number:</label>
                <input
                    type="text"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    placeholder="Enter your mobile number"
                    disabled={otpSent}
                />
                <button onClick={sendOTP} disabled={otpSent}>Send OTP</button>
            </div>

            {otpSent && (
                <div>
                    <label>One-Time Password (OTP):</label>
                    <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="Enter OTP"
                        disabled={otpVerified}
                    />
                    <button onClick={verifyOTP} disabled={otpVerified}>Verify OTP</button>
                </div>
            )}

            {otpVerified && userId && (
                <div>
                    <button onClick={deleteUserAccount}>Delete Account</button>
                </div>
            )}

            {message && <p>{message}</p>}
        </div>
    );
};

export default RemoveAccount;
